<template>
  <div class="container">
    <div class="tl spread">
      <div style="margin: 0 20px">
        <input
            v-model="gtin"
            placeholder="条形码/二维码值（查询时必填）"
            style="width: 240px;background-color: transparent"
            class="input_box"
        />
        <input
            v-model="batchNumber"
            placeholder="批次（非必填）"
            style="width: 200px;background-color: transparent"
            class="input_box"
        />
        <span class="button"  @click="handleSearch">查询</span>
        <span class="button" @click="handleRest">重置</span>
      </div>
      <div class="spread">
        <ve-bmap
            v-if="mapShow"
            ref="mapRef"
            height="98%"
            :settings="chartSettings"
            :series="chartSeries"
            :tooltip="chartTooltip"
            :events="chartEvents"
        >
          <div
              class="chain-block-box"
              v-show="companyList && companyList.length > 0"
          >
            Hash值区块链验证:
            <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
            <i class="el-icon-thumb"></i>
            {{ hashVal }}
          </span>
            <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>
          </div>
          <div class="default-desc col-fff tc">默认显示最新50条流向记录</div>
        </ve-bmap>
      </div>
    </div>
  </div>

</template>

<script>
import { createHash } from "@/util/util";
import { mapConfig } from "./custom_map_config.js";

export default {
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        if (params.seriesType === "effectScatter") {
          // return `${params.marker}${params.value[2]}<br>${
          //   params.value[5] || params.value[6]
          // }-${params.value[3]}-${params.value[4]}`;
        } else if (params.seriesType === "lines") {
          return `${params.data.fromName} > ${params.data.toName}<br>
                  ${params.data.goodsName}[${params.data.batch}]<br>
                  发货车辆:${params.data.soCarNumber || "无车辆信息"}-收货车辆:${params.data.rcCarNumber || "无车辆信息"}`;
        }
      },
    };
    const _this = this;
    this.chartEvents = {
      click: (v) => {},
      finished: (_) => {},
    };
    return {
      gtin: "",
      batchNumber: "",
      mapShow: false,
      companyList: [],
      chartSeries: [],
      hashFlag: false,
      hashVal: createHash(30),
    };
  },
  computed: {},
  created() {

  },
  mounted() {

    this.initMap();
  },
  methods: {
    initMap(params) {
      const loading = this.$loading({
        target:document.querySelector('.container'),
        lock: true,
        text: "数据加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.mapShow = false;
      this.chartSeries = [];
      this.$extraApi
        .get(`/v1/pc/flow/goods`,{ opt: 2, gtin: this.gtin, batchNumber: this.batchNumber })
        .then((response) => {
          let res = response.data;
          if (res.length > 0) {
            let convertData = this.convertData(res);
            let arr = [];
            let planePath =
              "path://M.6,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705";
            convertData.forEach((item, index) => {
              arr.push(
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.fromData,
                },
                {
                  type: "effectScatter",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  rippleEffect: {
                    //涟漪特效
                    period: 4, //动画时间，值越小速度越快
                    brushType: "stroke", //波纹绘制方式 stroke, fill
                    scale: 4, //波纹圆环最大限制，值越大波纹越大
                  },
                  symbol: "circle",
                  itemStyle: {
                    normal: {
                      color: "#3ed4ff",
                    },
                  },
                  data: item.toData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 1,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0.7,
                    color: "#fff",
                    symbolSize: 3,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 0,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                },
                {
                  type: "lines",
                  coordinateSystem: "bmap",
                  zlevel: 2,
                  effect: {
                    show: true,
                    period: 6,
                    trailLength: 0,
                    symbol: planePath,
                    symbolSize: 15,
                  },
                  lineStyle: {
                    normal: {
                      color: "#3ed4ff",
                      width: 1,
                      opacity: 0.4,
                      curveness: 0.2,
                    },
                  },
                  data: item.lineData,
                }
                // {
                //   type: "lines",
                //   coordinateSystem: "bmap",
                //   zlevel: 1,
                //   effect: {
                //     show: true,
                //     period: 6, //箭头指向速度，值越小速度越快
                //     trailLength: 0.7, //特效尾迹长度[0,1]值越大，尾迹越长重
                //     // symbol: "arrow", //箭头图标
                //     color: "#fff",
                //     symbolSize: 5, //图标大小
                //   },
                //   lineStyle: {
                //     normal: {
                //       color: "#3ed4ff",
                //       width: 1, //尾迹线条宽度
                //       // opacity: 1, //尾迹线条透明度
                //       curveness: 0.3, //尾迹线条曲直度
                //     },
                //   },
                //   data: item.lineData,
                // }
              );
            });
            this.chartSeries = arr;
          }
          this.mapShow = true;
          this.getGeo();
        });
        loading.close();
    },
    handleSearch() {
      if (this.gtin) {
        this.initMap();
      } else {
        this.$message({
          showClose: true,
          message: "条形码/二维码值为必填项",
          type: "warning",
        });
      }
    },
    handleRest() {
      this.gtin = "";
      this.batchNumber = "";
      this.initMap();
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    convertData(data) {
      let result = [];
      let companyList = [];
      let afterData = [];
      let tempArr = [];
      // for (let i = 0; i < data.length; i++) {
      //   if (
      //     tempArr.findIndex(
      //       (t) => t.fromCompanyId == data[i].from.company_id
      //     ) === -1 &&
      //     tempArr.findIndex((t) => t.toCompanyId == data[i].to.company_id) ===
      //       -1
      //   ) {
      //     afterData.push({
      //       from: data[i].from,
      //       to: data[i].to,
      //       info: {
      //         goods_name: data[i].info.goods_name,
      //         batches: [data[i].info.batch],
      //       },
      //     });
      //     tempArr.push({
      //       fromCompanyId: data[i].from.company_id,
      //       toCompanyId: data[i].to.company_id,
      //     });
      //   } else {
      //     for (let j = 0; j < afterData.length; j++) {
      //       if (
      //         afterData[j].from.company_id == data[i].from.company_id &&
      //         afterData[j].to.company_id == data[i].to.company_id
      //       ) {
      //         afterData[j].info.batches.push(data[i].info.batch);
      //         break;
      //       }
      //     }
      //   }
      // }
      // data = afterData;
      for (let i = 0; i < data.length; i++) {
        let fromData = [];
        let toData = [];
        let lineData = [];
        let fromLong = data[i].from.longitude;
        let fromLat = data[i].from.latitude;
        let toLong = data[i].to.longitude;
        let toLat = data[i].to.latitude;
        fromData = [[fromLong, fromLat, data[i].from.companyName]];
        companyList.push({
          longitude: fromLong,
          latitude: fromLat,
        });
        toData = [[toLong, toLat, data[i].to.companyName]];
        lineData = [
          {
            fromName: data[i].from.companyName,
            toName: data[i].to.companyName,
            goodsName: data[i].info.name,
            batch: data[i].info.batchNumber,
            soCarNumber: data[i].info.fromCarNumber,
            rcCarNumber: data[i].info.toCarNumber,
            coords: [
              [fromLong, fromLat],
              [toLong, toLat],
            ],
          },
        ];
        companyList.push({
          longitude: toLong,
          latitude: toLat,
        });
        result.push({ fromData, toData, lineData });
      }
      // for (let i = 0; i < data.length; i++) {
      //   let fromData = [];
      //   let toData = [];
      //   let lineData = [];
      //   fromData = [
      //     [
      //       data[i].from.company_longitude,
      //       data[i].from.company_latitude,
      //       data[i].from.company_name,
      //     ],
      //   ];
      //   companyList.push({
      //     longitude: data[i].from.company_longitude,
      //     latitude: data[i].from.company_latitude,
      //   });
      //   toData = [
      //     [
      //       data[i].to.company_longitude,
      //       data[i].to.company_latitude,
      //       data[i].to.company_name,
      //     ],
      //   ];
      //   lineData = [
      //     {
      //       fromName: data[i].from.company_name,
      //       toName: data[i].to.company_name,
      //       goodsName: data[i].info.goods_name,
      //       batch: data[i].info.batch,
      //       coords: [
      //         [data[i].from.company_longitude, data[i].from.company_latitude],
      //         [data[i].to.company_longitude, data[i].to.company_latitude],
      //       ],
      //     },
      //   ];
      //   companyList.push({
      //     longitude: data[i].to.company_longitude,
      //     latitude: data[i].to.company_latitude,
      //   });
      //   result.push({ fromData, toData, lineData });
      // }
      this.companyList = companyList;
      return result;
    },
    getGeo() {
      if (this.companyList.length > 0) {
        setTimeout((_) => {
          let pointArr = [];
          this.companyList.forEach((item) => {
            pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
          });
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let view = map.getViewport(eval(pointArr));
          let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
          let centerPoint = view.center;
          map.centerAndZoom(centerPoint, mapZoom);
        }, 200);
      }
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
  },
};
</script>
<style scoped lang="scss">
  .container{
    height: 100%;
  }
.input_box{
  border: 2px solid #409eff;
  color: #F2F6FC;
  height: 40px;
  border-radius: 15px;
  text-align:center;
  vertical-align:middle;
  margin-right: 10px;
}
$color: #2194E0;
@keyframes sheen {
  0% {
    transform: skewY(-45deg) translateX(0);
  }
  100% {
    transform: skewY(-45deg) translateX(12.5em);
  }
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  &:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }

  &:hover {
    cursor: pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);

    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }

  }
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #afb4db;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #afb4db;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #afb4db;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #afb4db;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1A366A ;
  color: #F2F6FC;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.container{
  height: 100%;
  width: 100%;
}
  .spread{
    height: 100%;
  }
</style>
